import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { JohnDeereOrganization, JohnDeereBrand } from 'data/schema-data'
import { graphql } from 'gatsby'
import isEmptyString from 'utils/is-empty-string'
import scrollTo from 'utils/scroll-to'
import { GatsbyImage } from 'gatsby-plugin-image'
import UnstyledButton from 'components/button/unstyled-button'
import Template from './product-template'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const trackApplyForFinancing = e => {
  const value = e.currentTarget.attributes['data-value'].value
  gtag(
    'event',
    gtagEvents.open_credit_application,
    value
      ? {
          currency: 'USD',
          value,
        }
      : {}
  )
  fbq(
    'trackCustom',
    fbqEvents.Lead,
    value
      ? {
          currency: 'USD',
          value,
        }
      : {}
  )
}

const trackBuyOnline = e => {
  const value = e.currentTarget.attributes['data-value'].value
  gtag(
    'event',
    gtagEvents.open_buy_online,
    value
      ? {
          currency: 'USD',
          value,
        }
      : {}
  )
  fbq(
    'trackCustom',
    fbqEvents.Lead,
    value
      ? {
          currency: 'USD',
          value,
        }
      : {}
  )
}

const trackProductBrochure = () => {
  gtag('event', gtagEvents.view_brochure)
  fbq('trackCustom', fbqEvents.ViewBrochure)
}

const Product = props => {
  const {
    data: {
      allDeereProduct: { nodes: allDeereProductNodes },
      allDeereOffer: { nodes: allDeereOfferNodes },
      allHutsonPromotion: { nodes: allHutsonPromotionNodes },
      deereProduct,
      deereProduct: {
        attachmentsBrochure,
        bodyHtml,
        buyOnlineLink,
        handle,
        outOfStock,
        productBrochure,
        reviews,
        sanityData: {
          brand,
          category,
          categoryCode,
          configureEnabled,
          keyFeatures,
          images: sanityImages,
          options,
          series,
          showPrice,
          subcategory,
          subcategoryCode,
          title,
        },
        sku,
        slug,
        videos,
      },
    },
    location,
  } = props

  const price = showPrice ? options[0].price : deereProduct.productPrice
  const showStartingAtPrice = showPrice && options.length > 1
  const displayPrice = showPrice && !showStartingAtPrice ? price : null
  const startingAtPrice = showStartingAtPrice ? price : null

  const offers = [
    ...(Array.isArray(allHutsonPromotionNodes)
      ? allHutsonPromotionNodes.map(node => {
          return {
            title: node.title,
            html: node.html,
            link: node.link || node.slug,
          }
        })
      : []),
    ...(Array.isArray(allDeereOfferNodes) ? allDeereOfferNodes : []),
  ]

  const similar = []
  if (Array.isArray(allDeereProductNodes)) {
    const similarProducts = fetchSimilarProducts(allDeereProductNodes, sku)
    similar.push(
      ...similarProducts.map(obj => {
        return {
          id: obj.sku,
          image: obj.image,
          imageAlt: obj.imageAlt,
          link: obj.slug,
          subtitle: `${subcategory.title}/${series.title}`,
          title: obj.name,
        }
      })
    )
  }

  const meta = {
    description:
      deereProduct.description || `Learn more about the ${brand.name} ${title} at Hutson.`,
    keywords: [
      category.title,
      subcategory.title,
      series.title,
      title,
      ...deereProduct.keywords,
    ].toString(),
    images: [],
    title: `${brand.name} ${title} | Hutson Inc`,
  }

  const images = []

  if (Array.isArray(sanityImages) && sanityImages.length > 0) {
    sanityImages.forEach(obj => {
      images.push({
        original: obj.asset.original,
        thumbnail: obj.asset.thumbnail,
        originalAlt: '',
        thumbnailAlt: '',
        renderItem: _renderImg,
        renderThumbInner: _renderImgThumb,
      })
      meta.images.push(obj.asset.url)
    })
  } else if (deereProduct.image) {
    images.push({
      original: deereProduct.image,
      thumbnail: deereProduct.image,
      originalAlt: deereProduct.imageAlt || title,
      thumbnailAlt: deereProduct.imageAlt || title,
    })
    meta.images.push(deereProduct.image)
  }

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: category.title,
      link: `/${category.handle}/`,
    },
    {
      name: subcategory.title,
      link: `/${category.handle}/${subcategory.handle}/`,
    },
    {
      name: series.title,
      link: `/${category.handle}/${subcategory.handle}/${series.handle}/`,
    },
    {
      name: title,
      link: slug,
    },
  ]
  const pricingLinks = []
  if (buyOnlineLink && !outOfStock) {
    pricingLinks.push(
      <OutboundLink
        href={buyOnlineLink}
        key='buy-online'
        id='buy-online'
        onClick={trackBuyOnline}
        data-value={price}
      >
        Buy online
      </OutboundLink>
    )
  }
  let secondaryButton
  if (configureEnabled) {
    secondaryButton = {
      as: SecondaryButtonA,
      href: `https://build.hutsoninc.com/configure/${handle}`,
      id: 'build-your-own',
      children: 'Build Your Own',
    }
  }
  if (subcategory.ocaEquipmentType) {
    const ocaLink = `https://creditapp.financial.deere.com/?dealer=034321&ref=${sku}&type=${subcategory.ocaEquipmentType}&country=us`
    if (secondaryButton) {
      pricingLinks.push(
        <OutboundLink
          href={ocaLink}
          key='apply-for-financing'
          id='apply-for-financing'
          onClick={trackApplyForFinancing}
          data-value={price}
        >
          Apply for financing
        </OutboundLink>
      )
    } else {
      secondaryButton = {
        'as': SecondaryButtonA,
        'href': ocaLink,
        'id': 'apply-for-financing',
        'children': 'Apply for financing',
        'onClick': trackApplyForFinancing,
        'data-value': price,
      }
    }
  }
  if (outOfStock) {
    pricingLinks.push(
      <a href='#similar' onClick={scrollTo} key='similar-equipment-pricing-link'>
        View similar equipment
      </a>
    )
  }
  if (offers.length > 0) {
    pricingLinks.push(
      <a href='#offers' onClick={scrollTo} key='offers-discounts-pricing-link'>
        View offers and discounts
      </a>
    )
  }

  const resourceLinks = []
  if (productBrochure) {
    resourceLinks.push(
      <a
        href={productBrochure}
        target='_blank'
        rel='noopener noreferrer'
        id='request-brochure'
        key='product-brochure'
        onClick={trackProductBrochure}
      >
        View product brochure
      </a>
    )
  }
  if (attachmentsBrochure) {
    resourceLinks.push(
      <a
        href={attachmentsBrochure}
        target='_blank'
        rel='noopener noreferrer'
        id='request-attachments-brochure'
        key='attachments-brochure'
        onClick={trackProductBrochure}
      >
        View attachments &amp; implements brochure
      </a>
    )
  }

  const keyFeaturesFormatted = keyFeatures.map(obj => ({
    body: obj._rawBody,
    image: obj.image.asset.gatsbyImageData,
    title: obj.title,
  }))

  const [showVideo, setShowVideo] = useState({})

  const toggleShowVideo = url => {
    const newState = {
      ...showVideo,
      [url]: !showVideo[url],
    }
    setShowVideo(newState)
  }

  const _renderVideo = item => {
    return showVideo[item.embedUrl] ? (
      <div className='image-gallery-image'>
        <div className='video-wrapper'>
          <iframe title='Related Video' src={item.embedUrl} frameBorder='0' allowFullScreen />
        </div>
      </div>
    ) : (
      <div>
        <PlayButton
          aria-label='Load video'
          onClick={() => toggleShowVideo(item.embedUrl)}
          role='button'
        />
        <div className='image-gallery-gatsby-image'>
          <div className='image-gallery-gatsby-image-inner'>
            <GatsbyImage
              image={item.original}
              alt={item.originalAlt}
              objectFit='contain'
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </div>
    )
  }

  const galleryImages = [
    ...images,
    ...(Array.isArray(videos)
      ? videos.map(video => {
          return {
            original: video.thumbnail.childImageSharp.original,
            thumbnail: video.thumbnail.childImageSharp.thumbnail,
            originalAlt: '',
            thumbnailAlt: '',
            embedUrl: `https://www.youtube.com/embed/${video.id}?rel=0`,
            renderItem: _renderVideo,
            renderThumbInner: _renderImgThumb,
          }
        })
      : []),
  ]
  return (
    <Template
      breadcrumbs={breadcrumbs}
      buttonText={displayPrice || startingAtPrice ? 'Talk to Sales' : 'Get a Quote'}
      description={isEmptyString(bodyHtml) ? null : bodyHtml}
      disclaimer='Prices and availability may vary by dealer. Taxes not included. Images for illustration purposes only. Financing subject to approved credit by John Deere Financial. Down payment may be required to receive financing. Restrictions may apply outside of Hutson’s area of responsibility. See dealer for details.'
      displayPrice={displayPrice}
      equipmentBrand='John Deere'
      equipmentCondition='new'
      features={deereProduct.features}
      images={galleryImages}
      keyFeatures={keyFeaturesFormatted}
      offers={offers}
      outOfStock={outOfStock}
      overview={deereProduct.overview}
      price={price}
      pricingLinks={pricingLinks}
      productType='john-deere'
      quoteFormHeader={displayPrice || startingAtPrice ? 'Talk to Sales' : 'Get a Quote'}
      quoteFormSubmitButtonClass='submit-quote'
      quoteFormHiddenValues={[
        {
          name: 'Category Code',
          value: categoryCode,
        },
        {
          name: 'Subcategory Code',
          value: subcategoryCode,
        },
      ]}
      resourceLinks={resourceLinks}
      reviews={reviews}
      similar={similar}
      secondaryButton={secondaryButton}
      specs={deereProduct.specs}
      startingAtPrice={startingAtPrice}
      title={title}
    >
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:url' content={location.href} />
        {meta.images.map((img, key) => (
          <meta property='og:image' content={img} key={key} />
        ))}
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            'description': meta.description,
            'name': title,
            'image': meta.images[0],
            sku,
            'mpn': deereProduct.baseCode,
            'category': `${category.title}/${subcategory.title}/${series.title}`,
            'brand': JohnDeereBrand,
            'manufacturer': JohnDeereOrganization,
            ...(Array.isArray(reviews?.reviews) && reviews.reviews.length > 0
              ? {
                  review: reviews.reviews.map(review => ({
                    '@type': 'Review',
                    'author': {
                      '@type': 'Person',
                      'name': review.author,
                    },
                    'datePublished': review.date,
                    'description': review.text,
                    'name': review.title,
                    'reviewRating': {
                      '@type': 'Rating',
                      'bestRating': '5',
                      'ratingValue': review.rating.overall,
                    },
                  })),
                  ...(reviews.overview && {
                    aggregateRating: {
                      '@type': 'AggregateRating',
                      'ratingValue': reviews.overview.rating.overall.toFixed(1),
                      'bestRating': 5,
                      'reviewCount': reviews.overview.count,
                    },
                  }),
                }
              : {}),
          })}
        </script>
      </Helmet>
    </Template>
  )
}

const SecondaryButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 0 0 15px;
  text-decoration: none;
`

const PlayButton = styled(UnstyledButton)`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  bottom: 0;
  cursor: pointer;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  z-index: 1;

  :hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.9);
  }

  :after {
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    content: '';
    display: block;
    left: 40px;
    margin: 0 auto;
    position: absolute;
    top: 16.5px;
  }
`

const _renderImg = item => {
  return (
    <div className='image-gallery-gatsby-image'>
      <div className='image-gallery-gatsby-image-inner'>
        <GatsbyImage
          image={item.original}
          alt={item.originalAlt}
          objectFit='contain'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      {item.description && <span className='image-gallery-description'>{item.description}</span>}
    </div>
  )
}

const _renderImgThumb = item => {
  return (
    <div className='image-gallery-gatsby-image'>
      <div className='image-gallery-gatsby-image-inner'>
        <GatsbyImage
          image={item.thumbnail}
          alt={item.thumbnailAlt}
          objectFit='contain'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  )
}

const fetchSimilarProducts = (allDeereProductNodes, sku) => {
  const matchIndex = allDeereProductNodes.findIndex(obj => obj.sku === sku)
  const similar = []

  for (let i = 1; i <= 3; i++) {
    const obj = allDeereProductNodes[matchIndex + i]
    if (obj) similar.push(obj)
  }

  for (let i = -1; i >= -3; i--) {
    const obj = allDeereProductNodes[matchIndex + i]
    if (obj) similar.push(obj)
  }

  return similar.splice(0, 3)
}

export const pageQuery = graphql`
  query productQuery($sku: String!, $series: String!) {
    deereProduct(sku: { eq: $sku }) {
      attachmentsBrochure
      baseCode
      bodyHtml
      buyOnlineLink
      description
      features {
        content
        title
      }
      handle
      image
      imageAlt
      keywords
      outOfStock
      overview
      productBrochure
      productPrice
      reviews {
        reviews {
          author
          date
          text
          title
          pros
          cons
          usage
          recommended
          location
          rating {
            overall
            value
            quality
            ease
          }
        }
        overview {
          count
          recommendedPercentage
          rating {
            overall
            value
            quality
            ease
          }
          stars {
            stars1
            stars2
            stars3
            stars4
            stars5
          }
        }
      }
      sanityData {
        brand {
          name
        }
        category {
          handle
          title
        }
        categoryCode
        configureEnabled
        keyFeatures: features {
          title
          _rawBody
          image {
            asset {
              gatsbyImageData(width: 900)
            }
          }
        }
        images {
          asset {
            original: gatsbyImageData(width: 680)
            thumbnail: gatsbyImageData(layout: FIXED, width: 92)
            url
          }
        }
        options {
          price
        }
        series {
          handle
          title
        }
        showPrice
        subcategory {
          handle
          ocaEquipmentType
          title
        }
        subcategoryCode
        title
      }
      sku
      slug
      specs {
        name
        specs {
          description
          name
        }
      }
      videos {
        id
        thumbnail {
          childImageSharp {
            original: gatsbyImageData(width: 680)
            thumbnail: gatsbyImageData(layout: FIXED, width: 92)
          }
        }
      }
    }
    allDeereOffer(filter: { productList: { in: [$sku] } }) {
      nodes {
        description
        disclaimer
        id
        slug
        subTitle
        title
      }
    }
    allHutsonPromotion(
      filter: { productList: { elemMatch: { product: { eq: $sku }, type: { eq: "john-deere" } } } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        html
        link
        slug
        title
      }
    }
    allDeereProduct(
      filter: { sanityData: { series: { handle: { eq: $series } } } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        image
        imageAlt
        name
        sku
        slug
      }
    }
  }
`

export default Product
